#hero {
    height: 88vh;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(3, 1fr);
}
.heroImgBkg {
    grid-column: 2 / span 2;
    grid-row: 1 / span 3;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, var(--l-txt), var(--l-bkg));
    box-shadow: 0 -0.5vh 1vh #0003 inset;
    transition: 0.25s;
}
.dmode .heroImgBkg {
    background-image: linear-gradient(to bottom, var(--d-txt), var(--d-bkg));
}
.pfp {
    width: 100%;
    height: 100%;
    background-image: url(../media/images/pfp.png);
    filter: grayscale(1);
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
}


.ctaBtnWrap {
    grid-column: 4 / span 7;
    grid-row: 3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ctaBtn {
    width: 10vh;
    height: 10vh;
    border-radius: 100%;
    user-select: none;
    cursor: pointer;
    border: none;
    background: var(--hover) url(../media/icons/downarrow.svg) center 0.5vh;
    background-size: 100%;
    transition: 0s;
    box-shadow: 0vh 0.5vh 0.5vh rgba(0, 0, 0, 0.5);
}
.ctaBtn:hover {
    background: var(--hover) url(../media/icons/downarrow.svg) center 30.5vh;
    background-size: 100%;
    transition: all 0s;
    transition: background 1s;
    transition-timing-function: cubic-bezier(0.5,0.25,0.5,1.25);
}
.ctaBtn:active {
    position: relative;
    top: 0.5vh;
    box-shadow: 0vh 0vh 0.5vh rgba(0, 0, 0, 0.5);
}

.typeBoxContainer {
    grid-column: 4 / span 7;
    grid-row: 1 / span 2;
    display: block;
    width: 90%;
    height: 90%;
    align-self: center;
    background-color: #000;
    box-shadow: 0 0.5vh 0.5vh #0005;
    position: relative;
    border-radius: 10px;
}
#typeArea {
    overflow-y: scroll;
    height: 90%;
    margin-top: 0.25rem;
}
#typeBoxTitleBar {
    height: 1.25rem;
    width: 100%;
    background-color: #333333;
    border-radius: 10px 10px 0 0;
}
#typeBoxTitleBar .title {
    color: #fff;
    padding-left: 1.5rem;
    padding-right: 3rem;
    margin-left: 0.25rem;
    background-color: #000;
    border-radius: 10px 10px 0 0;
    font-size: 0.75rem;
    user-select: none;
}
.typeBox {
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.75rem;
    font-weight: bold;
    width: 100%;
    text-align: left;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    user-select: text;
}
.typeBox .typeText {
    flex-shrink: 1;
}
.typeBox .caret {
    flex-grow: 1;
}
.typeBox .gt {
    flex-grow: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    user-select: none;
}
#dirText pre {
    color: #fff;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    user-select: text;
}
#dirText.hidden {
    height: 0;
    overflow: hidden;
}
.caret { animation: blinkCaret 0.75s step-start infinite; }
.caret.active { animation: unset; }
.caret.end, .gt.hidden {
    opacity: 0;
    animation: unset;
}
@keyframes blinkCaret {
    0%, 15% { opacity: 1; }
    50% { opacity: 0; }
    75%, 100% { opacity: 1; }
}
#mHeroText, #mHeroText h2 {
    transition: 0.25s;
}

@media only screen and (max-width: 1199px) {
        .heroImgBkg {
            grid-column: 1 / span 2;
        }
        .typeBoxContainer, .ctaBtnWrap {
            grid-column: 3 / span 8;
        }
    }
    @media only screen and (max-width: 1139px) {
        .heroImgBkg {
            display: none;
        }
        .typeBoxContainer, .ctaBtnWrap {
            grid-column: 1 / span 10;
        }
}
@media only screen and (max-width: 1139px) {
    #hero {
        background-image: url(../media/images/pfp.png);
        background-size: contain;
        background-position-x: center;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }
    #mHeroText {
        grid-column: 1 / span 10;
        grid-row: 2 / span 1;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    #mHeroText h2 {
        background-color: var(--l-bkg);
    }
    .dmode #mHeroText h2 {
        background-color: var(--d-bkg);
    }
}