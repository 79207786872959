header {
    width: 100%;
    height: 12vh;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 20px #0008;
    border-bottom: 1vh solid #000;
    transition: 0.25s;
}
.dmode header {
    border-color: #fff;
}
header * {
    transition: 0.25s;
}
.headerWrap {
    flex-basis: 40%;
}
h1 {
    max-width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--hover);
    user-select: none;
    cursor: url(../media/images/fuego.png), auto;
}
.dmode h1 {
    color: var(--l-txt);
}
#headerSpacer {
    flex-grow: 1;
}
nav {
    user-select: none;
}
header nav {
    flex-basis: 40%;
    display: flex;
}
.navBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}
.navBtn:hover { background-color: var(--l-hover); }
.dmode .navBtn:hover { background-color: var(--d-hover); }

.modeBtn {
    flex-basis: 12vh;
    width: 12vh;
    height: 11vh;
    position: relative;
    z-index: 2;
    cursor: pointer;
}
.modeBtn:hover { background-color: var(--l-hover); }
.dmode .modeBtn:hover { background-color: var(--d-hover); }
.modeBtnBar {
    position: absolute;
    top: 5vh;
    right: 3vh;
    width: 6vh;
    height: 2vh;
    border-radius: 2vh;
    background-color: var(--d-hover);
    box-shadow: 0.5vh -0.5vh 0.25vh #000 inset;
    filter: opacity(50%);
}
.dmode .modeBtnBar {
    background-color: var(--l-hover);
    box-shadow: -0.5vh 0.5vh 0.5vh #000B inset;
}
.modeSwitch {
    position: absolute;
    top: 4vh;
    right: 2vh;
    z-index: 1;
    background-color: var(--d-txt);
    width: 4vh;
    height: 4vh;
    border-radius: 100%;
    border: 0.75vh solid var(--d-txt);
    overflow: hidden;
}
.dmode .modeSwitch {
    background-color: var(--l-txt);
    border-color: var(--l-txt);
    right: 6vh;
    transform: rotate(180deg);
}
.modeSwitchInverter {
    background-color: var(--l-txt);
    width: 50%;
    height: 100%;
    margin-left: 50%;
}
.dmode .modeSwitchInverter {
    background-color: var(--d-txt);
    margin-left: 0;
    margin-right: 50%;
}

header .menu {
    width: 12vh;
    height: 11vh;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}
header .menu:hover {
    background-color: var(--l-hover);
}
.dmode header .menu:hover {
    background-color: var(--d-hover);
}
header .menu div {
    height: 1vh;
    width: 60%;
    background-color: #000;
    position: absolute;
    left: 2.4vh;
    border-radius: 1vh;
}
.dmode header .menu div {
    background-color: #fff;
}
header .menu.closed :nth-child(1) {
    top: 2.5vh;
}
header .menu.closed :nth-child(2) {
    top: 5.25vh;
}
header .menu.closed :nth-child(3) {
    top: 8vh;
}
header .menu.open {
    height: 12vh;
}
header .menu.open div {
    top: 5.25vh;
}
header .menu.open :nth-child(1) {
    transform: rotate(225deg);
}
header .menu.open :nth-child(2) {
    width: 0;
    left: 6vh;
    top: 5.25vh;
}
header .menu.open :nth-child(3) {
    transform: rotate(315deg);
}

footer {
    position: relative;
    z-index: 1;
    height: 12vh;
    border-top: 1vh solid #000;
    box-shadow: 0 0 2vh #000;
    display: flex;
    align-items: center;
}
.dmode footer {
    border-color: #fff;
}
footer address {
    flex-basis: 45%;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
}
footer nav {
    height: 100%;
    flex-basis: 55%;
    display: flex;
}
footer .navBtn {
    width: 100%;
    height: 100%;
    transition: 0.25s;
}

@media only screen and (max-width: 1139px) {
    .headerWrap {
        flex-grow: 1;
        margin-left: 12vh;
        margin-right: 12vh;
    }
    h1 {
        font-size: 1.5rem;
    }
    #headerSpacer {
        display: none;
    }
    .modeBtn {
        position: absolute;
        top: 0;
        right: 0;
    }
    header nav {
        position: absolute;
        flex-direction: column;
        justify-content: space-around;
        left: -100%;
        width: 100%;
        height: 100vh;
        font-size: 2rem;
        backdrop-filter: blur(3px);
    }
    header:has(.open) nav {
        top: 0;
        left: 0;    
    }
    header .navBtn {
        background-color: #fffffff0;
        height: 100%;
        width: 60%;
        margin-right: 40%;
    }
    .dmode header .navBtn {
        background-color: #282c34f0;
    }
    footer {
        height: 6vh;
    }
    footer nav {
        display: none;
    }
}
@media only screen and (max-width: 514px) {
    .modeBtn {
        display: none;
    }
    .headerWrap {
        margin: 0 auto;
    }
    header .navBtn {
        width: 100%;
        margin: 0 auto;
    }
    header .menu.closed { top: 12vh; }
}